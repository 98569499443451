@import "Scss/variables";

.colaz {
	.colaz-row {
		h1 {
			@media (min-width: $bootstrap-grid-lg){
				padding-top: 100px;
				padding-bottom: 150px;
			}
		}
		.colaz-url {
			display: block;
			height: 550px;
			position: relative;
			overflow: hidden;
			.colaz-photo {
				transform: scale(1);
				transition: all 1s ease-in-out;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
			.colaz-description {
				width: 100%;
				font-size: 250%;
				padding: 1.5rem 3.5rem 2.875rem;
				background: transparent;
				transition: all .5s linear;
				position: absolute;
				bottom: 0;
				color: $white;
			}
			&:hover {
				.colaz-photo {
					transform: scale(1.08);
				}
				.colaz-description {
					background-color: $cherry;
				}
			}
		}
	}
}
.bg-urban-s {
	background-image: url('../../img/585-urban-s.jpg');
}
.bg-urban-m {
	background-image: url('../../img/585-urban-m.jpg');
}
.bg-urban-l {
	background-image: url('../../img/585-urban-l.jpg');
}

.dorm-description {
	font-family: $ff-roboto;
	font-size: 1rem;
	line-height: 1.875;
}
