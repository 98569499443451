@import "Scss/variables";

.near-by-popover {
	a{
		text-decoration: none;
		color: $whiteText;
		font-family: $ff-roboto;
	}
	img {
		max-width: 100px;
	}
	p {
		text-align: justify;
	}
}

.popover-map {
	img {
		max-width: 100px;
	}
	p {
		padding: 10px;
		font-family: $ff-roboto;
		text-indent: 0;
		background-color: $light-yellow;
		color: $cherry;
		width: 550px;
		text-align: justify;
	}
}