// Colors
$white: #fff;
$whiteText: #dedcdd;
$grey: #6d6d6d;
$dark-grey: #374140;
$light-yellow: #b9fc00;
$yellow: darken($light-yellow, 10%);
$cherry: #660023;

// Sizes
$bootstrap-grid-xs: 320px;
$bootstrap-grid-sm: 576px;
$bootstrap-grid-md: 768px;
$bootstrap-grid-lg: 992px;
$bootstrap-grid-xl: 1200px;
$bootstrap-grid-xxl: 1600px;

// Width
$size:(
    xs: $bootstrap-grid-xs,
	sm: $bootstrap-grid-sm,
	md: $bootstrap-grid-md,
	lg: $bootstrap-grid-lg,
	xl: $bootstrap-grid-xl,
	xxl: $bootstrap-grid-xxl
);
//Font Weights
$font-weight-thin: 200;
$font-weight-almost-normal: 300;
$font-weight-normal: 400;
$font-weight-normal-plus: 500;
$font-weight-bold: 700;

//Fonts
$ff-roboto: 'Roboto Slab', serif;
$ff-poppins: 'Poppins', sans-serif;