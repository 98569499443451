@import "Scss/variables";

.carousel-container {
	position: relative;
	padding-right: 8.54700855%;
	padding-top: 8.54700855%;
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 14.28571429%;
		left: 8.54700855%;
		right: 0;
		background: $light-yellow 0 2px;
		z-index: 0;
	}

	img {
		border: $white 8px solid;
	}

	.carousel-control-prev-icon,
	.carousel-control-next-icon {
		height: 100px;
		width: 100px;
		outline: #b9fc00;
		background-size: 100%, 100%;
		background-image: none;
		font-family: $ff-poppins;
		&:after {
			font-size: 55px;
			color: $light-yellow;
		}
	}
	.carousel-control-next-icon:after {
		content: '>';
	}
	.carousel-control-prev-icon:after {
		content: '<';
	}
}