@import "Scss/variables";

.facilities-container {
	font-family: $ff-roboto;
}

ul.two-columns-list {
	list-style: none;
	line-height: 300%;
	margin: 0;
	padding: 0;
	@media (min-width: $bootstrap-grid-lg){
		margin-left: 5%;
		column-count: 2;
	}

	a {
		position: relative;
		text-decoration: none;
		padding: 0 0 8px;
		&:before {
			content: "";
			background: $light-yellow;
			height: 2px;
			width: 0;
			position: absolute;
			left: -1px;
			bottom: -1px;
			transition: all .3s ease-in-out;
		}
		&:hover {
			&:before {
				visibility: visible;
				width: 100%;
			}
		}
	}
}