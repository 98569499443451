@import "Scss/variables";

.contact-container {
	a {
		text-decoration: none;
		color: $white;
		font-weight: $font-weight-bold;
		font-size: 1.125rem;
		line-height: 1.4;
		&:hover {
			color: $light-yellow;
		}
	}
}